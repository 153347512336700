<template>
  <v-sheet style="height: 100%; background-color: transparent" class="d-flex flex-column justify-space-between">
    <!--   Sidebar chat header -->
    <ws-sidebar-window-header
      :title="$t('webinar.main.preview.chat')"
      @close="$emit('close')"
      style="position: relative; z-index: 0"
      :is-chat-turned-on="isChatTurnedOn"
      @set-turned-on="isChatTurnedOn = $event"
      :checked-value="checkedValue"
      :user="user"
    />

    <v-divider :color="wsACCENT"/>

    <div
      class="rounded d-flex flex-column justify-space-between flex-grow-1"
      style="height: calc(100% - 60px); "
    >
      <!--   NO CHAT MESSAGES -->
      <div v-if="chatMessages.length === 0 || !isChatTurnedOn" class="d-flex align-center justify-center px-7 text-center" style="color: #fff; height: 100%">
        <ws-sidebar-window-no-content
          :icon="isChatTurnedOn ? 'mdi-forum-outline' : 'mdi-block-helper' "
          :title="isChatTurnedOn ? $t('webinar.main.preview.no_messages') : $t('webinar.main.preview.chat_turned_off') "
          :subtitle="isChatTurnedOn ? $t('webinar.main.preview.no_messages_text') : $t('webinar.main.preview.turn_on_chat')"
        />
      </div>

      <!--   Block with user messages   -->
      <div v-else style="color: #fff;  height: 100%; " class="pa-2 scrollable " id="webinar_chat_messages_content">
        <!--                    scrollable-->

        <webinar-chat-message
            v-for="(message,index) in chatMessages" :key="index + 'message'"
            :message="message"
        />



      </div>


      <v-divider :color="wsACCENT"/>

      <!--   Chat window footer -->
      <div
        :style="`${isChatTurnedOn ? 'opacity: 1' : 'opacity: 0.5'}`"
      >
        <ws-sidebar-window-footer>
          <template #input>
            <input
                @input="isTextMessage = true"
                v-model="entityDataChat.text"
                @keydown.enter="handleEnter" type="text"
                style="outline: none; width: 100%; color : var(--wsWHITE)"
                class="px-2"
                :placeholder="$t('webinar.main.preview.print_message')"
                :disabled="!isChatTurnedOn"
            >
            <ws-tooltip
              v-if="!isTextMessage"
              top
              :text="isChatTurnedOn ? $t('webinar.main.preview.attach_file') : ''"
              style="position: absolute; top: 16px; right: 16px"
            >
              <v-hover v-slot="{hover}" >
                <v-icon
                  size="24"
                  color="#fff"
                  class="pa-1 rounded"
                  :style="`background-color: ${hover && isChatTurnedOn ? wsACCENT : 'transparent'}; cursor: ${ isChatTurnedOn ? 'pointer' : 'auto'}`"
                >
                  mdi-paperclip
                </v-icon>
              </v-hover>
            </ws-tooltip>
            <v-hover v-slot="{hover}" v-else >
              <v-icon
                size="24"
                color="#fff"
                class="pa-1 rounded"
                :style="`background-color: ${hover && isChatTurnedOn ? wsACCENT : 'transparent'}; cursor: ${ isChatTurnedOn ? 'pointer' : 'auto'}; position: absolute; top: 16px; right: 16px`"
                @click="sendMessage"
              >
                mdi-send
              </v-icon>
            </v-hover>
          </template>
        <!--   Sidebar chat footer   -->
        </ws-sidebar-window-footer>

      </div>
    </div>
  </v-sheet>

</template>


<script>
import WsSidebarWindowNoContent
  from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowNoContent.vue";
import WsSidebarWindowFooter from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowFooter.vue";
import WsSidebarWindowHeader from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowHeader.vue";
import webinars from "@modules/webinars/mixins/webinars";
import chats from "@modules/chats/mixins/chats";
import WebinarChatMessage from "@modules/webinars/components/Webinar/admin/room/sidebars/chat/webinarChatMessage.vue";

export default {
  name: "wsSidebarChat.vue",
  components: {WebinarChatMessage, WsSidebarWindowHeader, WsSidebarWindowFooter, WsSidebarWindowNoContent},
  mixins : [webinars,chats],
  props: {
    user: {
      type: Boolean,
      default: false
    },
    checkedValue: {
      type: String,
      default: ''
    }
  },
  watch : {
    SELECTED_CHAT_UID() {
      this.initChat()
    }
  },
  data() {
    return {
      chatMessages: [],
      isChatTurnedOn: true,
      entityDataChat: {},
      isTextMessage: false,
      disableListenerFunction : () => {}
    }
  },
  methods: {
    handleEnter(e){
      if(!e.shiftKey) {
        e.preventDefault();
        this.sendMessage()
      }
    },
    async initChat() {
      if (!this.SELECTED_CHAT_UID) {
        return
      }
      if (!this.$chats.socket || (this.$chats.socket && !this.$chats.socket.isConnected) ) {
        setTimeout(this.initChat , 1000)
        return
      }

      let chatMessages = await this.$chats.socket.getChatMessages()
      if (chatMessages && chatMessages.messages && chatMessages.messages.length > 0) {
        this.chatMessages = chatMessages.messages
        this.scrollMessagesToEnd()
      }

      setTimeout(() => {
        this.disableListenerFunction =  this.$chats.socket.listen('message/add' , {} , this.addMessage)
      },5000)

    },
    addMessage(message) {
      if (message && message.uid) {
        this.chatMessages.push(message)
        this.scrollMessagesToEnd()
      }
    },
    scrollMessagesToEnd() {
      setTimeout(() => {
        const contentDiv = document.getElementById("webinar_chat_messages_content")
        console.log('contentDiv',contentDiv , contentDiv.scrollTop, contentDiv.scrollHeight)
        contentDiv.scrollTop = contentDiv.scrollHeight;
      },100)
    },
    sendMessage() {
      this.$chats.socket.emit("message/add", {messageType: "TEXT", messageContent: this.entityDataChat.text })
      this.entityDataChat = {}
      this.isTextMessage = false

    },
    countCurrentTime() {
      const date = new Date();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      this.entityDataChat.currentTime =  `${hours}:${minutes}`
    },

  },
  mounted() {
    this.initChat()
  },
  beforeMount() {
    this.disableListenerFunction()
  }

}
</script>

<style scoped lang="scss">
.white-input-text ::v-deep textarea::placeholder {
  color: #9AB2C4!important;
}
.white-input-text ::v-deep input, .white-input-text ::v-deep textarea {
  color: #fff!important;
}
.scrollable {
  overflow-y: scroll;
  height: 100%;

  &::v-deep {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #4E6678;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #4E6678;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}
</style>
