export default {
    methods : {
    },
    computed : {
       SELECTED_CHAT_UID() {
           return this.$store.state.fineChats.selectedChatUid
       },
        CHAT_USER() {
           return this.$store.state.fineChats.user
        }
    },
}