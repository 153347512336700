<template>
  <div class="d-flex" :class="[{'justify-end' : IS_OWNER_MESSAGE}]">

    <!-- User Avatar-->
    <div v-if="!IS_OWNER_MESSAGE">
      <v-sheet
          v-if="!message.user.img"
          :color="wsDARK"
          class="d-flex align-center justify-center mr-1"
          style="border-radius: 50%"
          min-width="24" min-height="24"
          width="24" height="24"
      >
        <h6 class="wsWHITE" style="font-size: 8px; margin-top: 2px; line-height: 0" v-if="message.user.firstname">{{ message.user.lastname[0] }}{{ message.user.firstname[0] }}</h6>
      </v-sheet>
    </div>

    <!-- Message Content-->
    <v-sheet
        class="pa-2 mb-3"
        :color="wsACCENT + '33'"
        :class="IS_OWNER_MESSAGE ? 'owner-message' : 'outer-message'"
    >
      <h5 >{{ message.user.firstname }}</h5>
      <h5 class=" font-weight-regular">{{ message.content }}</h5>
    </v-sheet>

  </div>

</template>

<script>
import chats from "@modules/chats/mixins/chats";

export default {
  name: "webinarChatMessage",
  mixins : [chats],
  props : {
    message : {
      type: Object,
      default() {
        return {
          user : {}
        }
      }
    }
  },
  computed : {
    IS_OWNER_MESSAGE() {
      return this.message.user.uid === this.CHAT_USER.uid
    }
  }
}
</script>



<style scoped>
.outer-message {
  background-color: #00000022 !important;
  border-radius: 8px;
  border-top-left-radius: 0 !important;
  color: var(--wsWHITE)
}
.owner-message {
  border-radius: 8px;
  border-top-right-radius: 0 !important;
  background-color: var(--wsBACKGROUND) !important;
  color : var(--wsDARKER)
}
</style>